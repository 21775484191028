var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "load-review-client-container"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": _vm.$route.query.nickname,
      "left-text": "返回",
      "left-arrow": ""
    }
  }), _c('div', {
    staticClass: "app-main-container"
  }, [_c('div', {
    staticClass: "load-review-client-container__content"
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _c('van-list', {
    attrs: {
      "loading": _vm.loading,
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getClients
    }
  }, _vm._l(_vm.orders, function (order, index) {
    return _c('van-cell', {
      key: index,
      staticClass: "load-review-client-cell",
      attrs: {
        "center": "",
        "is-link": "",
        "value": order.line.name + '线'
      },
      on: {
        "click": function click($event) {
          return _vm.toDetail(order.client);
        }
      },
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_c('span', [_vm._v(_vm._s(order.client.name))]), order.is_urgent ? _c('van-tag', {
            staticClass: "load-review-client-container__content__cell__tag",
            attrs: {
              "type": "danger"
            }
          }, [_vm._v("加急")]) : _vm._e()];
        },
        proxy: true
      }], null, true)
    });
  }), 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }